<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    name: 'BarCharVue',
    mixins: [reactiveProp],
    extends: Bar,
    props: { chartData: Object, maxNumberYTicks: Number, chartYaxisLabel: String },
    data: function() {
        return {
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: this.maxNumberYTicks,
                                callback: function(value, index, values) {
                                    return value.toLocaleString('en-US')
                                }
                            },
                            gridLines: {
                                display: true
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.chartYaxisLabel
                            }
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return tooltipItems.yLabel.toLocaleString('en-US')
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                height: 200
            }
        }
    },
    mounted() {
        // this.chartData is created in the mixin
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        maxNumberYTicks: function(nextVal) {
            if (nextVal) {
                this.$data.options.scales.yAxes[0].ticks.maxTicksLimit = nextVal
            }
        },
        chartYaxisLabel: function(nextVal) {
            if (nextVal) {
                this.$data.options.scales.yAxes[0].scaleLabel.labelString = nextVal
                this.renderChart(this.chartData, this.options)
            }
        }
    }
}
</script>
